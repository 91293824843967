import { IBasePathParams } from 'common/models/requestModels';
import { ILocationModel } from 'common/helpers/location.helper';
import { ITestModel } from 'entities/Test/Test.models';
import { IPlayerModel } from 'entities/Player/Player.models';
import { IAdminModel } from 'entities/Admin/Admin.models';
import { IAcademyModel } from 'entities/Academy/Academy.models';

export interface IPlayerTestModel extends Components.Schemas.PlayerToTestDto {
  fieldMetadata?: any;
}
export interface IPlayerTestPureModel extends Components.Schemas.PlayerToTestDto {}
export interface IPlayerTestCollection extends Components.Schemas.PlayerToTestPureCollectionDto {}

export interface IPlayerTestQueueTaskCount extends Components.Schemas.TaskCountDto {}

export interface IPlayerTestCollectionFilter extends Paths.PlayerToTestControllerList.QueryParameters {
  pageIndex?: number;
  testFilter?: Partial<ITestModel>;
  academyFilter?: Partial<IAcademyModel>;
  playerFilter?: Partial<IPlayerModel>;
  uploadByFilter?: Partial<IAdminModel>;
  trainerFilter?: Partial<IAdminModel>;
  location?: ILocationModel;
}

export interface IPlayerTestVideoPoint extends Components.Schemas.VideoPointDto {}
export interface IPlayerTestVideoBoxPoint extends Components.Schemas.CreateVideoBoxPointDto {}
export interface IPlayerTestUpdateMove extends Components.Schemas.MovePlayerToTestDto {
  id: string;
}

export interface IPlayerTestUpdateParams
  extends Components.Schemas.UpdatePlayerToTestDto,
    Paths.PlayerToTestControllerUpdate.PathParameters {
  query?: Paths.PlayerToTestControllerUpdate.QueryParameters;
}
export interface IPlayerTestGetNextParams extends IPlayerTestCollectionFilter, IBasePathParams {}

export type TPlayerTestParamsModel = IPlayerTestUpdateParams | IBasePathParams;

export interface IPlayerTestResultModel extends Components.Schemas.PlayerTestResultDto {}
export interface IPlayerTestResultUpdateParams
  extends Components.Schemas.UpdatePlayerTestResultValueDto,
    Paths.PlayerTestResultControllerUpdate.PathParameters {}
export type TPlayerTestResultParamsModel = IPlayerTestResultUpdateParams | IBasePathParams;

export enum EPlayerTestSuccessMessage {
  Delete = 'PlayerTest has been successfully deleted',
  Add = 'PlayerTest has been successfully created',
  Edit = 'PlayerTest has been successfully edited'
}

export enum EPlayerTestFailMessage {
  Edit = 'Произошла ошибка. Проверьте поля и повторите снова',
  WrongArrowCircleMessages = 'Time for circle 2 must be less than circle 1'
}

export enum EPlayerToTestUploaderType {
  Player = 'player',
  Trainer = 'trainer'
}

export enum EPlayerToTestUploaderTypeText {
  Player = 'Player',
  Trainer = 'Coach'
}

export enum EPlayerTestStatus {
  New = 'new',
  AIModeration = 'aIModeration',
  Moderation = 'moderation',
  Accept = 'accept',
  Reject = 'reject'
}

export enum EPlayerTestInvestigationStatus {
  None = 'none',
  Needed = 'needed',
  Done = 'done',
  Resolved = 'resolved'
}
export enum EPlayerTestRenderStatus {
  None = 'none',
  InProgress = 'inProgress',
  Done = 'done',
  Error = 'error'
}

export enum EPlayerTestStatusText {
  New = 'New',
  AIModeration = 'AI moderation',
  Moderation = 'Moderation',
  Accept = 'Accepted',
  Reject = 'Rejected'
}

export enum EPlayerTestRejectReasonText {
  One = 'Плохое качество видео',
  Two = 'Плохое освещение видео',
  Three = 'Видео слишком длинное',
  Four = 'Неприемлемое содержание видео',
  Five = 'AI не смог оценить видео',
  Six = 'Превышено кол-во ошибок при выполнении',
  Seven = 'Тест не поддерживается в AI',
  Eight = 'Ошибка во время скачивания видео',
  Nine = 'Неправильное выполнение упражнения',
  Ten = 'Камера должна быть неподвижна',
  Eleven = 'Игрок должен быть полностью в кадре на протяжении всего видео',
  Twelve = 'Ошибка записи видео',
  Thirteen = 'Неверное выполнение задания',
  Fourteen = 'Не удалось найти фишки/конусы на видео',
  Fifteen = 'Неправильно установлена камера'
}
export enum EPlayerTestRejectReason {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
  Seven = 7,
  Eight = 8,
  Nine = 9,
  Ten = 10,
  Eleven = 11,
  Twelve = 12,
  Thirteen = 13,
  Fourteen = 14,
  Fifteen = 15
}

export const tablePlayerTestConfig = [
  {
    title: 'Test name',
    dataIndex: 'testName',
    key: 'testName',
    ellipsis: true
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    ellipsis: true
  },
  {
    title: 'Player name',
    dataIndex: 'playerTest',
    key: 'playerTest',
    ellipsis: true
  },
  {
    title: 'Video id',
    dataIndex: 'videoId',
    key: 'videoId',
    ellipsis: true
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    ellipsis: true
  },
  {
    title: 'Date',
    dataIndex: 'testDate',
    key: 'testDate',
    ellipsis: true,
    sorter: true
  },
  {
    title: 'Type',
    dataIndex: 'testType',
    key: 'testType',
    ellipsis: true,
    sorter: false
  }
];

export enum ERejectCodesStrings {
  One = 'One',
  Two = 'Two',
  Three = 'Three',
  Four = 'Four',
  Five = 'Five',
  Six = 'Six',
  Seven = 'Seven',
  Eight = 'Eight',
  Nine = 'Nine',
  Ten = 'Ten',
  Eleven = 'Eleven',
  Twelve = 'Twelve',
  Thirteen = 'Thirteen',
  Fourteen = 'Fourteen'
}

export const ERejectCodes: { [key: number]: ERejectCodesStrings } = {
  1: ERejectCodesStrings.One,
  2: ERejectCodesStrings.Two,
  3: ERejectCodesStrings.Three,
  4: ERejectCodesStrings.Four,
  5: ERejectCodesStrings.Five,
  6: ERejectCodesStrings.Six,
  7: ERejectCodesStrings.Seven,
  8: ERejectCodesStrings.Eight,
  9: ERejectCodesStrings.Nine,
  10: ERejectCodesStrings.Ten,
  11: ERejectCodesStrings.Eleven,
  12: ERejectCodesStrings.Twelve,
  13: ERejectCodesStrings.Thirteen,
  14: ERejectCodesStrings.Fourteen
};

export enum EBaseResultExceptions {
  WrongResult = 'error.playerToTestWrongBaseResultException'
}

export enum EBaseResultErrorMessages {
  WrongArrowCircleTimes = 'Wrong difference between t13 and t14 base results',
  WrongArrowRightCircleTimes = 'Wrong difference between t31 and t32 base results',
  WrongArrowLeftCircleTimes = 'Wrong difference between t35 and t36 base results'
}
