import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Row,
  Switch,
  Tabs,
  Typography,
  Upload,
  Alert
} from 'antd';
import { useFormMapper } from '@axmit/antd4-helpers';
import { Link } from 'react-router-dom';
import { StoreBranch } from '@axmit/redux-communications';
import { DownloadOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import moment from 'moment/moment';
import { getHost } from 'common/helpers/axios.helper';
import { dateFormatter, getUTCEndOfDay, getUTCStartOfDay } from 'common/helpers/date.helper';
import { simpleDateFormat } from 'common/models/dateModels';
import { ERoutesPrivate } from 'common/models/routesModel';
import { ImageUpload } from 'common/components/ImageUpload/ImageUpload';
import { LocationInput } from 'common/components/Input/LocationInput';
import { ELocationType } from 'common/helpers/location.helper';
import { normalizeRequestBody } from 'common/helpers/normalize.helper';
import { isAcademyFranchiseActive, isAcademyFranchisee, isAcademyFranchisor } from 'common/helpers/franchise.helper';
import { phoneRule } from 'common/helpers/rules.helper';
import { Phone } from 'common/components/Phone/Phone';
import { EFileStatus } from 'common/components/Image/Image.models';
import { EErrorStatus } from 'common/models/requestModels';
import { HOST } from 'common/const/common.const';
import { copyToBuffer } from 'common/helpers/buffer.helper';
import { downloadFile } from 'common/helpers/loader.helper';
import { objectToQuery } from 'common/helpers/filters.helper';
import {
  EAcademyStatus,
  IAcademyModel,
  IAcademyParamsModel,
  IAcademyUpdateParams,
  IAcademyUpdateStatusParams
} from 'entities/Academy/Academy.models';
import { AcademyStatusSelector } from 'entities/Academy/components/Selector/AcademyStatusSelector';
import { AcademyWorkerTable } from 'entities/Academy/components/Form/AcademyWorkerTable';
import { AcademyTrainersTable } from 'entities/Academy/components/Form/AcademyTrainersTable';
import { AcademyPlayersTable } from 'entities/Academy/components/Form/AcademyPlayersTable';
import { getReferralLinkByCode } from 'entities/ReferralCode/ReferralCode.helper';
import { communicationFranchise, IFranchiseConnectedProps } from 'entities/Franchise/Franchise.communication';
import { communicationAdmin, IAdminConnectedProps } from 'entities/Admin/Admin.communication';
import { communicationPlayer, IPlayerConnectedProps } from 'entities/Player/Player.communication';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { getStatisticFileUrl } from 'entities/Statistic/Statistic.transport';
import { EStatisticAcademyType } from 'entities/Statistic/Statistic.models';
import { playerTransport } from 'entities/Player/Player.transport';
import { academyTransport } from 'entities/Academy/Academy.transport';
import { EPlayerStatuses, IPlayerCSVCollectionFilter } from 'entities/Player/Player.models';

interface IComponentProps {
  updateAcademy?: (params: IAcademyUpdateStatusParams) => void;
  updateAcademyStatus?: (params: IAcademyUpdateStatusParams) => void;
  academyModel: StoreBranch<IAcademyModel, IAcademyParamsModel>;
  academyId?: string;
}

type AllProps = IComponentProps & IFranchiseConnectedProps & IAdminConnectedProps & IPlayerConnectedProps & IAuthConnectedProps;

enum TabNames {
  AcademyWorkers = 'academyWorkers',
  Trainers = 'trainers',
  Players = 'players'
}
export interface IDate {
  from?: string;
  to?: string;
}

const AcademyFormComponent: React.FC<AllProps> = props => {
  const {
    academyModel,
    updateAcademy,
    academyId,
    updateAcademyStatus,
    addFranchiseModel,
    franchiseModel,
    franchiseRequestModel,
    deleteFranchiseModel,
    adminCollection,
    playerCollection,
    authModel,
    getPlayerCollection
  } = props;
  const history = useHistory();
  // @ts-ignore
  const { tab = TabNames.AcademyWorkers, id } = useParams();
  const { errors, loading, data, params } = academyModel;
  const adminMetaCount = adminCollection?.data?.meta?.count || 0;
  const playerMetaCount = playerCollection?.data?.meta?.count || 0;
  const submitButtonText = 'Save';
  const host = getHost();
  const franchiseData = React.useMemo(() => franchiseModel.data ?? data?.franchiseRequest?.franchise, [franchiseModel, data]);
  const isInFranchise = React.useMemo(() => isAcademyFranchiseActive(data), [data]);
  const isFranchisor = React.useMemo(() => isAcademyFranchisor(data), [data]);
  const isFranchisee = React.useMemo(() => isAcademyFranchisee(data), [data]);
  const canUpdateTestBalance = isFranchisee || isFranchisor;
  const isAcademyActive = React.useMemo(() => data?.status === EAcademyStatus.Active, [data]);
  const token = authModel.data?.access.token;
  const createdAt = data?.createdAt;
  const externalId = data?.externalId;
  const [doubleError, setDoubleError] = useState<any[] | null>(null);

  const [dateStatistic, setDateStatistic] = useState<IDate | undefined>({
    from: moment()
      .subtract(1, 'month')
      .startOf('day')
      .toISOString(),
    to: moment()
      .endOf('day')
      .toISOString()
  });

  const [form] = Form.useForm();
  const memoizedValue = useMemo(
    () => ({
      ...data,
      isAvailableRfsTests: data?.isAvailableRfsTests,
      hideTariffs: data?.hideTariffs,
      image: data?.image?.id,
      webhookUrl: data?.franchiseRequest?.franchise?.webhookUrl,
      ageGroups: data?.ageGroups ?? undefined
    }),
    [data]
  );

  const { fields } = useFormMapper(
    [
      'name',
      'id',
      'contactPhone',
      'rating',
      'contactName',
      'description',
      'address',
      'contactPosition',
      'site',
      'webhookUrl',
      'image',
      'status',
      'isAvailableRfsTests',
      'isPaid',
      'rejectReason',
      'hideTariffs',
      'externalId',
      'testBalance'
    ],
    memoizedValue,
    params,
    errors
  );

  const transformAcademy = () => {
    const { data } = academyModel;
    if (data) {
      addFranchiseModel({
        name: data?.name,
        description: data?.description,
        academy: data?.id
      });
    }
  };

  const transformToAcademy = () => {
    if (data?.franchiseRequest?.franchise?.id) {
      deleteFranchiseModel(data?.franchiseRequest?.franchise?.id);
    }
  };

  const editAcademy = (values: any) => {
    const { status, rejectReason, isAvailableRfsTests, isPaid, ...body } = values;

    if (academyId && updateAcademy) {
      const params: IAcademyUpdateParams = {
        id: academyId,
        ...body
      };

      if (isAvailableRfsTests !== undefined) {
        params.isAvailableRfsTests = isAvailableRfsTests;
      }
      if (isPaid !== undefined) {
        params.isPaid = isPaid;
      }
      const content = normalizeRequestBody(params);

      updateAcademy(content);
    }
  };

  const editStatus = (values: any) => {
    const { status, rejectReason } = values;

    if (academyId && updateAcademyStatus) {
      const params: IAcademyUpdateStatusParams = {
        id: academyId,
        status
      };

      if (values?.status === EAcademyStatus.Banned) {
        params.rejectReason = rejectReason;
      }

      updateAcademyStatus(params);
    }
  };

  const [showReason, setShowReason] = useState(false);
  const [autoAcceptMentor, setAcceptMentor] = useState(false);

  useEffect(() => {
    setShowReason(fields.find(item => item.name === 'status')?.value === EAcademyStatus.Banned);
  }, [fields]);

  const onFieldsChanged = (changedValue: any, allFields: any) => {
    if (allFields?.status) {
      setShowReason(allFields?.status === EAcademyStatus.Banned);
    }
  };

  const onTabChanged = (tab: string) => {
    history.replace(`${ERoutesPrivate.Academy}/${id}/${tab}`);
  };

  const onChangeDates = (values: any) => {
    const [from, to] = values ?? [];
    setDateStatistic({
      from: from && getUTCStartOfDay(from),
      to: to && getUTCEndOfDay(to)
    });
  };

  const handleChangePlayersCsv = (info: UploadChangeParam<UploadFile<File>>) => {
    const { file } = info;
    const { status, response } = file;
    const { message, doubles } = (response as any) || {};

    if (doubleError) {
      setDoubleError(null);
    }
    if (status === EFileStatus.Done) {
      message.success('Players successfully added');
      if (playerCollection.params) {
        getPlayerCollection(playerCollection.params);
      }
    }
    if (status === EFileStatus.Error || file?.error?.status === EErrorStatus.Unauthorized) {
      if (doubles) {
        setDoubleError(doubles);
      }
      // @ts-ignore
      message.error(response?.message || 'Something went wrong, try reload page or another file');
    }
  };

  const mentorInviteCopyLink = useCallback(
    async (e?: any) => {
      e?.stopPropagation();
      try {
        const { token } = await playerTransport.createInviteMentorsToken(academyId);
        const link = `${HOST}/invite-mentors?token=${token}`;

        await copyToBuffer(link);
        message.success('Link has been copied');
      } catch (e) {
        message.error('Fail');
      }
    },
    [data]
  );

  const coachInviteTokenCopy = useCallback(async (e?: any) => {
    e?.stopPropagation();
    try {
      const { token } = await academyTransport.createInviteTrainerToken({ academyId });

      if (token) {
        await copyToBuffer(token);
        message.success('Token has been copied');
      }
    } catch (e) {
      message.error('Fail');
    }
  }, []);

  return (
    <Row gutter={16}>
      <Col span={12}>
        <Form form={form} className="mt-5" onFinish={editAcademy} fields={fields} onValuesChange={onFieldsChanged}>
          <Row gutter={8} className="mb-5">
            <Col span={24} className="mb-5">
              <span className="basic__text_title" title="Personal info">
                Academy information
              </span>
            </Col>
            <Col span={12}>
              <Form.Item name="image" label="Avatar">
                <ImageUpload />
              </Form.Item>
            </Col>
            {isInFranchise && franchiseData && (
              <Col span={12}>
                <Col span={24} className="mt-5">
                  <DatePicker.RangePicker
                    onChange={onChangeDates}
                    format="DD.MM.YYYY"
                    value={[
                      dateStatistic?.from && (moment(dateStatistic?.from) as any),
                      dateStatistic?.to && (moment(dateStatistic?.to).utc() as any)
                    ]}
                    allowClear={true}
                  />
                </Col>
                <Col span={24} className="mt-5">
                  <span className="basic__text_label" title="Download statistic report">
                    Download statistic report
                  </span>
                  <a
                    href={`${host}${getStatisticFileUrl({
                      id: id,
                      statisticType: EStatisticAcademyType.Academy,
                      token,
                      date: dateStatistic
                    })}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-block mt-3"
                  >
                    <Button block type="primary" title="Academy">
                      <DownloadOutlined /> Academy
                    </Button>
                  </a>
                </Col>
                <Col span={24} className="mt-5">
                  <a
                    href={`${host}${getStatisticFileUrl({
                      id: franchiseData?.id,
                      statisticType: EStatisticAcademyType.Franchise,
                      token,
                      date: dateStatistic
                    })}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-block mt-3"
                  >
                    <Button block type="primary" title="Franchise">
                      <DownloadOutlined /> Franchise
                    </Button>
                  </a>
                </Col>

                <Col span={24} className="mt-5">
                  <span className="basic__text_label" title="Download billing report">
                    Download billing report
                  </span>
                  <a
                    href={`${host}${getStatisticFileUrl({
                      id: id,
                      statisticType: EStatisticAcademyType.Academy,
                      token,
                      isBilling: true,
                      date: dateStatistic
                    })}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-block mt-3"
                  >
                    <Button block type="primary" title="Academy">
                      <DownloadOutlined /> Academy
                    </Button>
                  </a>
                </Col>
                <Col span={24} className="mt-5">
                  <a
                    href={`${host}${getStatisticFileUrl({
                      id: franchiseData?.id,
                      statisticType: EStatisticAcademyType.Franchise,
                      token,
                      isBilling: true,
                      date: dateStatistic
                    })}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-block mt-3"
                  >
                    <Button block type="primary" title="Franchise">
                      <DownloadOutlined /> Franchise
                    </Button>
                  </a>
                </Col>
              </Col>
            )}
            <Col span={12}>
              <Form.Item name="name" label="Name">
                <Input />
              </Form.Item>
              <Col>
                <span className="basic__text_label" title=" Referral site link:">
                  Created at:
                </span>
                <br />
                <Typography.Paragraph ellipsis>
                  {createdAt ? dateFormatter(createdAt, simpleDateFormat) : '-'}
                </Typography.Paragraph>
              </Col>
            </Col>
            <Col span={12}>
              <Form.Item name="description" label="Description">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="rating" label="Rating">
                <InputNumber min={0} max={100} placeholder="Rating" disabled={loading} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="address" label="Address">
                <LocationInput types={[ELocationType.Cities]} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="contactName" label="Contact name">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item rules={[phoneRule]} name="contactPhone" label="Contact phone">
                <Phone size="middle" disabled={loading} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="contactPosition" label="Contact person position">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="site" label="Site">
                <Input />
              </Form.Item>
            </Col>
            {isFranchisor && (
              <Col span={12}>
                <Form.Item name="webhookUrl" label="Webhook Url">
                  <Input />
                </Form.Item>
              </Col>
            )}
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    min: 2,
                    message: 'External Id must be longer than or equal 2 characters'
                  },
                  {
                    max: 128,
                    message: 'External Id must be shorter than or equal 128 characters'
                  }
                ]}
                name="externalId"
                label="External Id"
              >
                <Input name="externalId" type="text" placeholder="External Id" disabled={loading} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <span className="basic__text_label" title="Referral code:">
                Referral code:
              </span>
              <br />
              <span className="basic__text_default" title={data?.ownReferralCode?.number}>
                {data?.ownReferralCode?.number}
              </span>
            </Col>
            <Col span={12}>
              <span className="basic__text_label" title=" Referral site link:">
                Referral link:
              </span>
              <br />
              <span className="basic__text_default" title={getReferralLinkByCode(data?.ownReferralCode?.number)}>
                <Typography.Paragraph ellipsis copyable>
                  {getReferralLinkByCode(data?.ownReferralCode?.number)}
                </Typography.Paragraph>
              </span>
            </Col>
            {!isInFranchise && isAcademyActive && (
              <Col span={24}>
                <Popconfirm placement="topRight" title="Are you sure?" onConfirm={transformAcademy} okText="Yes" cancelText="No">
                  <Button type="primary" loading={franchiseModel.loading} disabled={franchiseModel.loading}>
                    Transform to franchise
                  </Button>
                </Popconfirm>
              </Col>
            )}
            {canUpdateTestBalance && (
              <Col xs={24}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Test balance is required'
                    },
                    {
                      type: 'number'
                    }
                  ]}
                  name="testBalance"
                  label="Test balance"
                >
                  <InputNumber min={0} placeholder="Test balance" disabled={loading} />
                </Form.Item>
              </Col>
            )}
            {isFranchisor && (
              <Col span={12}>
                <Form.Item name="isAvailableRfsTests" label="Is available rfs tests" valuePropName="checked">
                  <Checkbox>Is available rfs tests</Checkbox>
                </Form.Item>
              </Col>
            )}
            <Col span={12}>
              <Form.Item name="isPaid" label="Is paid for academy players" valuePropName="checked">
                <Checkbox>Is paid</Checkbox>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="hideTariffs" label="Hide tariffs" valuePropName="checked">
                <Checkbox>Hide tariffs</Checkbox>
              </Form.Item>
            </Col>
            {isFranchisor && isAcademyActive && (
              <Col span={24}>
                <Popconfirm
                  placement="topRight"
                  title="Are you sure?"
                  onConfirm={transformToAcademy}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="primary" loading={franchiseRequestModel.loading} disabled={franchiseRequestModel.loading}>
                    Transform to academy
                  </Button>
                </Popconfirm>
              </Col>
            )}
          </Row>

          <Row justify="space-between" align="middle">
            <Col span={16}>
              <Form.Item className="mb-0">
                <Popconfirm title="Are you sure?" onConfirm={() => form.submit()} okText="Yes" cancelText="No">
                  <Button
                    className="mr-8"
                    htmlType="submit"
                    type="primary"
                    disabled={loading}
                    loading={loading}
                    title={submitButtonText}
                  >
                    {submitButtonText}
                  </Button>
                </Popconfirm>

                <Link to={ERoutesPrivate.Academy}>
                  <Button disabled={loading} loading={loading} title="Cancel">
                    Cancel
                  </Button>
                </Link>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Form className="mt-5" onFinish={editStatus} fields={fields} onValuesChange={onFieldsChanged}>
          <Col span={18}>
            <Row className="mb-5" align="middle">
              <span className="basic__text_title" title="Personal info">
                Change status
              </span>
            </Row>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Status is required'
                  }
                ]}
                name="status"
                label="Status"
              >
                <AcademyStatusSelector disabled={loading} />
              </Form.Item>
            </Col>
            <Col span={12} className={`${showReason ? 'd-block' : 'd-none'}`}>
              <Form.Item
                rules={[
                  {
                    required: showReason,
                    message: 'Reject reason is required'
                  }
                ]}
                label="Reject reason"
                name="rejectReason"
              >
                <Input.TextArea
                  maxLength={256}
                  placeholder="Reject reason"
                  disabled={loading}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Form.Item>
            </Col>
          </Col>
          <Row justify="space-between" align="middle">
            <Col span={16}>
              <Form.Item className="mb-0">
                <Button
                  className="mr-8"
                  htmlType="submit"
                  type="primary"
                  disabled={loading}
                  loading={loading}
                  title={submitButtonText}
                >
                  {submitButtonText}
                </Button>
                <Link to={ERoutesPrivate.Academy}>
                  <Button disabled={loading} loading={loading} title="Cancel">
                    Cancel
                  </Button>
                </Link>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Col span={12} className="mt-5">
          <Switch checkedChildren="Autoaccept" unCheckedChildren="manualaccept" onChange={setAcceptMentor} />
          <span className="basic__text_label" title="Upload players file">
            Upload players file (.csv):
          </span>
          <br />
          <Upload
            headers={{ authorization: `Bearer ${token}` }}
            accept=".csv"
            action={`/api/players/batch/${academyId}/csv?auto=${autoAcceptMentor}`}
            name="file"
            onChange={handleChangePlayersCsv}
          >
            <Button type="primary">Upload</Button>
          </Upload>
          {doubleError &&
            doubleError?.map((item, index) => (
              <Alert
                key={index}
                type="error"
                message={`Rows: ${item?.rows} Name: ${item?.firstName} ${item?.lastName} \n Birthday:${dateFormatter(
                  item?.birthday,
                  'DD.MM.YYYY'
                )}`}
                banner
              />
            ))}
        </Col>
      </Col>

      <Col span={12}>
        {isInFranchise && franchiseData && (
          <Row className="mb-5 mt-5" align="middle" gutter={[8, 8]}>
            <Col span={24}>
              <span className="basic__text_title" title="Personal info">
                Franchise Info
              </span>
            </Col>
            <Col span={12}>
              <span className="basic__text_label" title="Name:">
                Name:
              </span>
              <br />
              <span className="basic__text_default" title={franchiseData?.name}>
                {franchiseData?.name}
              </span>
            </Col>
            <Col span={12}>
              <span className="basic__text_label" title="Description:">
                Description:
              </span>
              <br />
              <span className="basic__text_default" title={franchiseData?.description}>
                {franchiseData?.description}
              </span>
            </Col>
            <Col span={12}>
              <span className="basic__text_label" title="ID:">
                ID:
              </span>
              <br />
              <span className="basic__text_default" title={franchiseData?.id}>
                {franchiseData?.id}
              </span>
            </Col>
            <Col span={12}>
              <span className="basic__text_label" title="Status:">
                Status:
              </span>
              <br />
              <span className="basic__text_default" title={franchiseData?.status}>
                {franchiseData?.status}
              </span>
            </Col>
          </Row>
        )}
        <Button className="ml-4" type="primary" title="Copy mentor invite link" onClick={mentorInviteCopyLink}>
          Copy mentor invite link
        </Button>
        <Button className="ml-4" type="primary" title="Copy coach invite token" onClick={coachInviteTokenCopy}>
          Copy coach invite token
        </Button>
        <Button
          className="ml-4"
          type="primary"
          title="Download CSV player links"
          onClick={() =>
            downloadFile(
              `${host}/api/trainers/players/magic-token/csv${objectToQuery({
                academy: id,
                statuses: [EPlayerStatuses.Active],
                hideArchived: true
              } as IPlayerCSVCollectionFilter)}`,
              token,
              'POST'
            )
          }
        >
          Download CSV player links
        </Button>
        <Row className="mb-5 mt-5" align="middle">
          <Tabs destroyInactiveTabPane onChange={onTabChanged} activeKey={tab}>
            <Tabs.TabPane key={TabNames.AcademyWorkers} tab="Academy workers">
              <span className="basic__text_title d-block mb-5" title="Academy workers">
                Academy workers (Count: {adminMetaCount})
              </span>
              <Col>
                <AcademyWorkerTable academyId={academyId} />
              </Col>
            </Tabs.TabPane>
            {isInFranchise && (
              <>
                <Tabs.TabPane key={TabNames.Trainers} tab="Coaches">
                  <span className="basic__text_title d-block mb-5" title="Coaches">
                    Coaches (Count: {adminMetaCount})
                  </span>
                  <Col>
                    <AcademyTrainersTable academyId={academyId} />
                  </Col>
                </Tabs.TabPane>
                <Tabs.TabPane key={TabNames.Players} tab="Players">
                  <span className="basic__text_title d-block mb-5" title="Players">
                    Players (Count: {playerMetaCount})
                  </span>
                  <Col>
                    <AcademyPlayersTable academyId={academyId} />
                  </Col>
                </Tabs.TabPane>
              </>
            )}
          </Tabs>
        </Row>
      </Col>
    </Row>
  );
};

export const AcademyForm = communicationAuth.injector(
  communicationPlayer.injector(communicationAdmin.injector(communicationFranchise.injector(AcademyFormComponent)))
);
