import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { WalletDirectTransactionForm } from 'entities/Wallet/components/Form/WalletDirectTransactionForm';
import { communicationUI, IUIConnectedProps } from 'entities/UI/UI.communication';
import { PlayerSelector } from 'entities/Player/components/PlayerSelector';
import { IPlayerModel } from 'entities/Player/Player.models';

type AllProps = IUIConnectedProps;

const WalletDirectTransactionModalComponent: React.FC<AllProps> = props => {
  const { closeUIWalletModal, UIWalletModal, clearUIWalletModal } = props;
  const { data } = UIWalletModal;
  const { isVisible, player, redirectUrl } = data || {};
  const [playerModel, setPlayerModel] = useState<IPlayerModel | undefined>(player);

  useEffect(() => {
    setPlayerModel(player);
  }, [player]);

  useEffect(() => {
    return () => clearUIWalletModal();
  }, [clearUIWalletModal]);

  return (
    <Modal
      bodyStyle={{ border: 'none' }}
      visible={isVisible}
      onCancel={closeUIWalletModal}
      footer={false}
      title="Direct transaction"
    >
      <PlayerSelector onChange={setPlayerModel} value={playerModel} placeholder="Search by player" disabled={!!player} />
      {!!playerModel && <WalletDirectTransactionForm player={playerModel} redirectUrl={redirectUrl} />}
    </Modal>
  );
};

export const WalletDirectTransactionModal = communicationUI.injector(WalletDirectTransactionModalComponent);
