import React, { useEffect, useMemo, useState } from 'react';
import { Avatar, Button, Col, Form, Input, InputNumber, Row } from 'antd';
import { useFormMapper } from '@axmit/antd4-helpers';
import { Link } from 'react-router-dom';
import { StoreBranch } from '@axmit/redux-communications';
import { CameraOutlined } from '@ant-design/icons';
import { ERoutesPrivate } from 'common/models/routesModel';
import { ImageUpload } from 'common/components/ImageUpload/ImageUpload';
import { VideoUpload } from 'common/components/Video/VideoUpload';
import { VideoPrivate } from 'common/components/Video/VideoPrivate';
import { useFilesLoading } from 'common/components/UseFilesLoading';
import { getImageLink } from 'common/helpers/loader.helper';
import { StoryStatusSelector } from 'entities/Story/components/Selectors/StoryStatusSelector';
import { IStoryCreateData, IStoryModel, TStoryParamsModel, IStoryUpdateData } from 'entities/Story/Story.models';
import { EImageSize } from 'entities/Image/Image.models';
import { LanguageSelect } from 'entities/Language/components/Selectors/LanguageSelect';
import { PlayerAgeGroupSelector } from 'entities/Player/components/Selector/PlayerAgeGroupSelector';

interface IComponentProps {
  createStory?: (data: IStoryCreateData) => void;
  updateStory?: (data: IStoryUpdateData) => void;
  deleteStory?: (id: string) => void;
  storyModel: StoreBranch<IStoryModel, TStoryParamsModel>;
  storyId?: string;
}

const StoryFormComponent: React.FC<IComponentProps> = props => {
  const { storyModel, updateStory, storyId, createStory } = props;
  const { errors, loading: storyLoading, data, params } = storyModel;
  const [avatarSrc, setAvatarSrc] = useState('');
  const [filesLoading, onChangeFileLoading] = useFilesLoading(['video']);
  const isUpdateForm = !!storyId;
  const loading = storyLoading || filesLoading;
  const submitButtonText = isUpdateForm ? 'Save' : ' Add';
  const videoId = data?.video?.id;
  const imageId = data?.image?.id;

  useEffect(() => {
    if (imageId) {
      getImageLink(imageId, EImageSize.X96).then(url => setAvatarSrc(url));
    } else {
      setAvatarSrc('');
    }
  }, [imageId]);

  const memoizedValue = useMemo(
    () => ({
      ...data,
      image: imageId,
      video: videoId
    }),
    [data]
  );
  const { fields } = useFormMapper(
    ['image', 'langs', 'title', 'ageGroups', 'order', 'video', 'status', 'url', 'description'],
    memoizedValue,
    params,
    errors
  );

  const addStory = (values: any) => {
    const { image, langs, title, ageGroups, order, video, status, description, url } = values;

    const newData: IStoryCreateData = {
      title,
      description,
      image,
      video,
      langs,
      ageGroups,
      order,
      status
    };
    if (url) {
      newData.url = url;
    }

    createStory && createStory(newData);
  };
  const editStory = (values: any) => {
    const { image, langs, title, ageGroups, order, video, status, description, url } = values;

    if (storyId && updateStory) {
      const newData: IStoryUpdateData = {
        title,
        description,
        image,
        video,
        langs,
        ageGroups,
        order,
        status,
        id: storyId
      };
      if (url) {
        newData.url = url;
      }

      updateStory(newData);
    }
  };

  return (
    <Form className="mt-5" onFinish={isUpdateForm ? editStory : addStory} fields={fields}>
      <>
        <Row className="mb-5" align="middle">
          <span className="basic__text_title" title="Information">
            Information
          </span>
        </Row>
        <Row gutter={[28, 10]}>
          <Col span={24}>
            <Form.Item
              name="title"
              label="Title"
              rules={[
                {
                  required: true,
                  message: 'Title is required'
                },
                {
                  min: 2,
                  message: 'Title must be longer than or equal 2 characters'
                },
                {
                  max: 1024,
                  message: 'Title must be shorter than or equal 1024 characters'
                }
              ]}
            >
              <Input name="title" type="text" placeholder="Title" disabled={loading} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="url"
              label="Redirect url"
              rules={[
                {
                  type: 'url',
                  message: 'Введите корректный URL!'
                }
              ]}
            >
              <Input name="url" type="text" placeholder="Redirect URL" disabled={loading} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              rules={[
                {
                  min: 2,
                  message: 'Description must be longer than or equal 2 characters'
                },
                {
                  max: 1024,
                  message: 'Description must be shorter than or equal 1024 characters'
                }
              ]}
              name="description"
              label="Description"
            >
              <Input.TextArea placeholder="Description" disabled={loading} autoSize={{ minRows: 10, maxRows: 15 }} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="order" label="Order">
              <InputNumber
                min={0}
                name="order"
                placeholder="If the field is empty, the history will be in random order"
                disabled={loading}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="image" label="Image">
              {storyId ? (
                <Avatar shape="square" size={100} className="avatar-editor__avatar" icon={<CameraOutlined />} src={avatarSrc} />
              ) : (
                <ImageUpload aspect={0} circularCrop={false} />
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="video" label="Video">
              {!storyId ? (
                <VideoUpload onLoading={(isLoading: boolean) => onChangeFileLoading('video', isLoading)} />
              ) : videoId ? (
                <VideoPrivate width={200} height={200} videoId={videoId} previewClass="radius-default" />
              ) : (
                <div className="mt-4">No video</div>
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="langs" label="Language">
              <LanguageSelect isSingle={false} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="ageGroups" label="Age groups">
              <PlayerAgeGroupSelector isSingle={false} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="status" label="Status">
              <StoryStatusSelector />
            </Form.Item>
          </Col>
        </Row>
      </>

      <Row justify="space-between" align="middle">
        <Col span={16}>
          <Form.Item className="mb-0">
            <Button
              className="mr-8"
              htmlType="submit"
              type="primary"
              disabled={loading}
              loading={loading}
              title={submitButtonText}
            >
              {submitButtonText}
            </Button>
            <Link to={ERoutesPrivate.Story}>
              <Button disabled={loading} loading={loading} title="Cancel">
                Cancel
              </Button>
            </Link>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export const StoryForm = StoryFormComponent;
